/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import {
  LeftIcon,
  ThreeDots,
  MaxIcon,
  LikeIcon,
  DislikeIcon,
  ShareIcon,
  CloseIcon,
  NextIcon,
} from "../../components/common/Icons";
import SeeAllButton from "../../components/Home/SeeAllButton";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import post_as_view_user_bg from "../../assets/images/png/post-view-user-profile-bg.png";
import post_as_view_user_pic from "../../assets/images/png/user-img2.png";
import post_as_view_user from "../../assets/images/png/user-img.png";
import { RWebShare } from "react-web-share";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import { UseTopCreatorsContext } from "../ContextApis/TopCreatorsAndFollow";
import DOMPurify from "dompurify";
import { UseInterestContext } from "../ContextApis/SettingsContext";
import showToast from "../ToastSucess";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.entry";
import SimilarPosts from "./SimilarPosts";
import showErrorToast from "../ToastError";
import { getToken } from "../ContextApis/TokenStore";

const PostAsViewByUser = () => {
  const [showThreeDots, setShowThreeDots] = useState(false);
  const [report, setReport] = useState(false);
  const [follow, setFollow] = useState(false);
  const [like, setLike] = useState(false);
  const [disLike, setDisLike] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [postData, setPostData] = useState([]);
  const [reportReasons, setReportReason] = useState("");
  const [reportCustomReason, setReportCustomReason] = useState("");
  const [username, setUsername] = useState("");
  const [incrimentLike, setincrimentLike] = useState(0);
  const [time, setTime] = useState("");
  const [pdf, setPdf] = useState(null);
  const [images, setImages] = useState([]);
  const [pageRendering, setPageRendering] = useState(false);
  const { likePost, disLikePost, markPostAsNotInterested } = UseProfileContext();
  const {
    getUserPostById,
    sharePost,
    repotPost,
    profileData,
    checkIsLike,
    checkIsFollow,
    deletePost,
  } = UseCreatePostData();
  const { getsimilarpost, similardata } = UseInterestContext();
  const { followUser, unFollowUser } = UseTopCreatorsContext();

  let localStorageUserName = localStorage.getItem("userName");
  // Check if localStorageUserName matches the username in postData
  const shouldShowDeleteButton = localStorageUserName === postData?.user?.userName;
  function handlegallreyImgages(btnName) {
    let realIndex = imgIndex + 1;
    if (btnName === "next" && realIndex <= postData?.awsUrl.length - 1) {
      setImgIndex(imgIndex + 1);
    }
    if (btnName === "prev" && imgIndex >= 1) {
      setImgIndex(imgIndex - 1);
    }
  }
  function handlePDFImgages(btnName) {
    let realIndex = imgIndex + 1;
    if (btnName === "next" && realIndex <= images.length - 1) {
      setImgIndex(imgIndex + 1);
    }
    if (btnName === "prev" && imgIndex >= 1) {
      setImgIndex(imgIndex - 1);
    }
  }
  function incrimentlikes() {
    if (!like) setincrimentLike(incrimentLike + 1);
  }
  function decrimentlikes() {
    if (like) {
      setincrimentLike(incrimentLike - 1);
    }
  }

  const { id } = useParams();
  const sanitizedHtml = DOMPurify.sanitize(postData?.description);

  useEffect(() => {
    let user = sessionStorage.getItem("userName") || localStorage.getItem("userName");
    setUsername(user);
  });
  const popupRef = useRef();
  async function getpostData() {
    let data = await getUserPostById(id);
    setPostData(data);
    let isFollow = await checkIsFollow(data?.user?.userName);
    if (isFollow === "following") {
      setFollow(true);
    }
  }
  const navigate = useNavigate();
  const hadleDeletePost = async () => {
    try {
      await deletePost(id);
      navigate("/home");
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };
  async function likeTrue() {
    let isLike = await checkIsLike(id);
    if (isLike === "Liked") {
      setLike(true);
    }
  }
  useEffect(() => {
    getpostData();
    likeTrue();
    getsimilarpost(id);
  }, [id]);
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowThreeDots(false);
        setReport(false);
      }
    }
    if (showThreeDots || report) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showThreeDots, report]);

  function handleLikes(id) {
    const like = {
      postId: id,
      likeType: "LIKE",
    };
    likePost(like);
  }
  function handleDisLikes(id) {
    const disLike = {
      postId: id,
      likeType: "DISLIKE",
    };
    disLikePost(disLike);
  }
  function handleReportReason(e) {
    setReportReason(e.target.value);
  }

  function handleReport(e) {
    e.preventDefault();
    const reportData = {
      reportedPostId: id,
      reportReason: reportReasons.split(" ").join("_").toUpperCase(),
      reportDescription: reportCustomReason,
    };
    if (reportData.reportReason) {
      repotPost(reportData);
      setReport(false);
      setReportCustomReason("");
      setReportReason("");
    } else {
      showErrorToast("Please add report reason !");
    }
  }

  const timeAgo = (dateString) => {
    // Parse the date string (assuming the format is "DD-MM-YYYY | hh:mm AM/PM")
    const [datePart, timePart] = dateString.split(" | ");
    const [day, month, year] = datePart.split("-");
    const dateFormatted = `${year}-${month}-${day} ${timePart}`;

    // Convert to Date object
    const then = new Date(dateFormatted);
    const now = new Date();
    const seconds = Math.floor((now - then) / 1000);
    const interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return `${interval} years ago`;
    }
    if (interval === 1) {
      return "1 year ago";
    }

    const months = Math.floor(seconds / 2592000);
    if (months > 1) {
      return `${months} months ago`;
    }
    if (months === 1) {
      return "1 month ago";
    }

    const days = Math.floor(seconds / 86400);
    if (days > 1) {
      return `${days} days ago`;
    }
    if (days === 1) {
      return "1 day ago";
    }

    const hours = Math.floor(seconds / 3600);
    if (hours > 1) {
      return `${hours} hours ago`;
    }
    if (hours === 1) {
      return "1 hour ago";
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes > 1) {
      return `${minutes} minutes ago`;
    }
    if (minutes === 1) {
      return "1 minute ago";
    }
    return "just now";
  };

  useEffect(() => {
    if (Object.keys(postData).length !== 0) {
      setTime(timeAgo(postData.postCreatedAt));
    }
  }, [postData]);
  const [fileType, setfileType] = useState("");

  async function loadPdf(pdfLink) {
    try {
      setPageRendering(true);
      const _PDF_DOC = await pdfjsLib.getDocument({ url: pdfLink }).promise;
      setPdf(_PDF_DOC);
      setPageRendering(false);
    } catch (error) {
      console.error("Error loading PDF: ", error.message);
    }
  }
  async function renderPage() {
    if (!pdf) return;
    setPageRendering(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const viewport = page.getViewport({ scale: 1 });
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      await page.render({ canvasContext: context, viewport }).promise;
      imagesList.push(canvas.toDataURL("image/png"));
    }
    setImages(imagesList);
    setPageRendering(false);
  }

  useEffect(() => {
    if (postData?.awsUrl?.length > 0) {
      const fetchFileFromUrl = async (url) => {
        const fileName = url;
        setfileType(fileName.includes(".pdf") ? "pdf" : "");
        if (fileName.includes(".pdf")) {
          loadPdf(postData?.awsUrl[0]);
        }
      };
      fetchFileFromUrl(postData.awsUrl[0]);
    }
  }, [postData?.awsUrl]);

  useEffect(() => {
    renderPage();
  }, [pdf]);
  let location = useLocation();
  const copyToClipboard = (url) => {
    navigator.clipboard
      .writeText("wised.in/" + location.pathname)
      .then(() => {
        showToast("URL copied to clipboard !");
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };
  return (
    <>
      {(showThreeDots || report) && (
        <div className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full"></div>
      )}
      {showThreeDots && (
        <div
          ref={popupRef}
          className="max-w-[185px] sm:max-w-[320px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]  text-center w-full overflow-hidden z-[60] sm:h-auto sm:block flex sm:flex-row flex-col items-center sm:scroll-auto justify-center shadow">
          {!shouldShowDeleteButton && (
            <>
              <a
                onClick={() => {
                  setReport(true);
                  setShowThreeDots(false);
                }}
                className="w-full font-normal sm:text-base text-sm text-red-500 py-2 inline-block cursor-pointer ">
                Report
              </a>
              <hr className="w-full" />
            </>
          )}
          {shouldShowDeleteButton && (
            <>
              <p
                onClick={() => {
                  setShowThreeDots(false);
                  hadleDeletePost(id);
                }}
                className="w-full font-normal sm:text-base text-sm py-2 text-red-500 cursor-pointer inline-block">
                Delete
              </p>
              <hr className="w-full" />
            </>
          )}
          <p
            onClick={() => {
              setShowThreeDots(false);
              copyToClipboard(id);
            }}
            className="w-full font-normal sm:text-base text-sm py-2  cursor-pointer inline-block">
            Copy link
          </p>
          {!shouldShowDeleteButton && (
            <>
              <hr className="w-full" />
              <p
                onClick={() => {
                  markPostAsNotInterested(postData?.id);
                  setShowThreeDots(false);
                }}
                className="w-full font-normal sm:text-base text-sm py-2  cursor-pointer inline-block">
                Not interested
              </p>
            </>
          )}
          <hr className="w-full" />
          <button
            className="w-full font-normal sm:text-base text-sm py-2 inline-block"
            onClick={() => setShowThreeDots(false)}>
            Cancel
          </button>
        </div>
      )}
      {report && (
        <form
          onSubmit={handleReport}
          ref={popupRef}
          className="max-w-[320px] sm:max-w-[372px] bg-white rounded-[16px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-center w-full overflow-hidden z-[60] sm:h-auto sm:block flex items-center sm:scroll-auto scroll-[0] sm:pt-[45px] shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] xl:pb-10 sm:px-[18px] sm:pb-[45px] px-6 py-5 flex-col">
          <p className=" font-medium sm:text-base text-sm text-center ">Report</p>
          <p className="xl:text-base font-normal text-sm sm:my-6 my-4 text-center">
            Use this option to report inappropriate content or behaviour and prevent further
            interaction with this user. Your action helps maintain a safe environment for our
            community. Thank you for your contribution to our platform's integrity.
          </p>
          <p className="xl:text-base font-normal text-sm sm:mb-6 mb-4  text-center">
            Why are you reporting this account ?
          </p>
          <div className="flex justify-center">
            <div className="">
              <div className="flex items-center sm:mb-4 mb-[6px] ">
                <input
                  onChange={handleReportReason}
                  type="radio"
                  name="report"
                  id="spam"
                  checked={reportReasons === "Spam"}
                  value={"Spam"}
                />
                <label
                  htmlFor="spam"
                  className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal">
                  Spam
                </label>
              </div>
              <div className="flex items-center sm:mb-4 mb-[6px]">
                <input
                  onChange={handleReportReason}
                  type="radio"
                  name="report"
                  checked={reportReasons === "Offensive contents"}
                  id="Offensive"
                  value={"Offensive contents"}
                />
                <label
                  htmlFor="Offensive"
                  className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal">
                  Offensive contents
                </label>
              </div>
              <div className="flex items-center sm:mb-4 mb-[6px]">
                <input
                  onChange={handleReportReason}
                  type="radio"
                  name="report"
                  id="Fake"
                  value={"Fake account"}
                />
                <label
                  htmlFor="Fake"
                  className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal">
                  Fake account
                </label>
              </div>
              <div className="flex items-center sm:mb-6 mb-[6px]">
                <input
                  onChange={handleReportReason}
                  type="radio"
                  name="report"
                  id="Other"
                  value={"Other"}
                />
                <label
                  htmlFor="Other"
                  className="sm:ms-[11px] ms-[14px] xl:text-base sm:text-sm text-xs font-normal">
                  Other
                </label>
              </div>
            </div>
          </div>
          <input
            onChange={(e) => setReportCustomReason(e.target.value)}
            type="text"
            value={reportCustomReason}
            placeholder="reason"
            className="bg-transparent border-b border-[#EEEFEF] w-[187px] max-sm:text-xs"
          />
          <div className="flex justify-center mt-6">
            <button className="inline-block me-[25px]">
              <div className="">
                <button
                  type="submit"
                  className=" text-white font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-primary rounded-[100px] mx-auto sm:px-7 px-6">
                  Report
                </button>
              </div>
            </button>
            <button className="inline-block">
              <div className="h-[40px]  justify-center items-center bg-white flex rounded-[100px] ">
                <button
                  type="button"
                  className=" text-black font-normal lg:text-base text-sm h-[40px] md:h-[50px] lg:h-[60px] w-[106px] md:w-[132px] flex items-center justify-center lg:px-10 bg-white rounded-[100px] mx-auto sm:px-7 px-6 border-grey border"
                  onClick={() => {
                    setReport(false);
                    setReportCustomReason("");
                    setReportReason("");
                  }}>
                  Cancel
                </button>
              </div>
            </button>
          </div>
          <div
            className="absolute right-[20px] top-[15px] cursor-pointer"
            onClick={() => setReport(false)}>
            <CloseIcon />
          </div>
        </form>
      )}
      {postData && (
        <div className=" max-w-[1800px] mx-auto sm:px-6 px-4 lg:pt-[26px] sm:pt-[31px] flex pb-5 max-lg:flex-col max-lg:mb-10">
          <div className=" w-3/4 max-lg:w-full sticky top-[50px] h-full">
            <div className=" flex justify-between pb-[10px] sm:pb-4">
              <button onClick={() => window.history.back()}>
                <LeftIcon />
              </button>
            </div>
            <div className="lg:px-15">
              <div className="flex justify-between">
                <Link
                  onClick={() => {
                    let token = getToken();
                    if (token === null || username === "Guest") {
                      localStorage.setItem(
                        "sharePath",
                        `/ViewByUserprofile/${profileData.userName}`
                      );
                    }
                  }}
                  to={`/ViewByUserprofile/${profileData.userName}`}>
                  <div className="flex items-center pb-4">
                    <div className="sm:pr-[10px] pr-[14px]">
                      <img
                        className="max-md:max-w-[39px] max-md:min-w-[39px] min-w-[48px] max-w-[48px] h-[48px] max-md:h-[39px] rounded-[50%]"
                        src={profileData.profileImageUrl || post_as_view_user}
                        alt="user"
                      />
                    </div>
                    <div>
                      <p className="sm:font-bold font-medium sm:text-lg text-sm">
                        {postData?.user?.fullName}
                      </p>
                      <p className="text-normal sm:text-base text-xs md:text-sm">
                        {postData?.user?.designation}
                        <span className="text-grey font-normal sm:text-base text-xs pl-2">
                          {time}
                        </span>
                      </p>
                    </div>
                  </div>
                </Link>
                {username !== "Guest" && (
                  <div className="relative">
                    <button
                      className="max-sm:rotate-90"
                      onClick={(e) => {
                        {
                          e.preventDefault();
                          setShowThreeDots(!showThreeDots);
                        }
                      }}>
                      <ThreeDots />
                    </button>
                  </div>
                )}
              </div>
              <p
                className="lg:pb-0 sm:pb-6 pb-0 sm:mb-9 mb-[9px] text-sm sm:text-base md:text-md sm:font-normal"
                dangerouslySetInnerHTML={{ __html: sanitizedHtml }}></p>
              <div className="relative">
                {postData?.awsUrl && postData?.awsUrl.length > 1 && (
                  <>
                    <button
                      style={{
                        cursor: `${
                          postData?.awsUrl.length - 1 === imgIndex ? "no-drop" : "pointer"
                        }`,
                      }}
                      onClick={() => handlegallreyImgages("next")}
                      className="bg-white flex items-center justify-center sm:w-[37px] w-[19px] sm:h-[37px] h-[19px] rounded-[50%] absolute top-1/2 right-[26px] sm:right-[38px] md:right-[59px] -translate-y-1/2 slider_btn shadow-md z-10">
                      <NextIcon />
                    </button>
                    <button
                      style={{
                        cursor: `${imgIndex === 0 ? "no-drop" : "pointer"}`,
                      }}
                      onClick={() => handlegallreyImgages("prev")}
                      className="bg-white flex items-center justify-center sm:w-[37px] w-[19px] sm:h-[37px] h-[19px] rounded-[50%] absolute top-1/2 left-[26px] sm:left-[38px] md:left-[59px] -translate-y-1/2 rotate-180 slider_btn shadow-md z-10">
                      <NextIcon />
                    </button>
                  </>
                )}
                {images.length > 1 && (
                  <>
                    <button
                      style={{
                        cursor: `${
                          postData?.awsUrl.length - 1 === imgIndex ? "no-drop" : "pointer"
                        }`,
                      }}
                      onClick={() => handlePDFImgages("next")}
                      className="bg-white flex items-center justify-center sm:w-[37px] w-[19px] sm:h-[37px] h-[19px] rounded-[50%] absolute top-1/2 right-[26px] sm:right-[38px] md:right-[59px] -translate-y-1/2 slider_btn shadow-md z-10">
                      <NextIcon />
                    </button>
                    <button
                      style={{
                        cursor: `${imgIndex === 0 ? "no-drop" : "pointer"}`,
                      }}
                      onClick={() => handlePDFImgages("prev")}
                      className="bg-white flex items-center justify-center sm:w-[37px] w-[19px] sm:h-[37px] h-[19px] rounded-[50%] absolute top-1/2 left-[26px] sm:left-[38px] md:left-[59px] -translate-y-1/2 rotate-180 slider_btn shadow-md z-10">
                      <NextIcon />
                    </button>
                  </>
                )}
                {postData.postType !== "IMAGES" && postData.postType !== "WRITE_UPS" ? (
                  <div className="rounded-[16px] sm:rounded-[31px] bg-[#F8F8F8] lg:py-6 sm:py-7 py-3 overflow-hidden relative">
                    <div className=" sm:px-12 px-7 max-sm:py-3 max-sm:px-7 flex justify-between sm:pb-7 pb-3">
                      <p className="font-semibold sm:text-md xl:text-lg text-sm">
                        Title of the document
                      </p>
                      <p className="font-semibold sm:text-md xl:text-lg text-sm">
                        {postData.postType && postData.postType.split("_").join(" ")}
                      </p>
                    </div>
                    <div className="w-full flex justify-center overflow-hidden text-center  max-h-[200px] sm:max-h-[300px] md:max-h-[400px] lg:max-h-[500px] xxl:max-h-[600px] ">
                      {fileType === "pdf" ? (
                        <div className="w-full flex justify-center bg-white">
                          <div id="page-loader" hidden={!pageRendering}>
                            Loading page...
                          </div>
                          <img
                            style={{ boxShadow: "0 0 17px -10px black" }}
                            className="h-full object-contain"
                            src={images[imgIndex]}
                            alt=""
                          />
                        </div>
                      ) : (
                        <img
                          src={Array.isArray(postData.awsUrl) && postData?.awsUrl[imgIndex]}
                          alt=""
                          className="w-full xl:max-h-[566px] sm:max-h-[400px] max-h-[280px]"
                        />
                      )}
                    </div>
                    <div className=" sm:px-12 px-7 max-sm:py-3 max-sm:px-7 flex justify-between sm:pt-7 pt-3">
                      <p className="font-semibold sm:text-md xl:text-lg text-xs">
                        {imgIndex + 1}/
                        {fileType === "pdf"
                          ? images.length
                          : postData?.awsUrl && postData?.awsUrl?.length}{" "}
                        {images.length == 0 && (
                          <Link
                            onClick={() => {
                              let token = getToken();
                              if (token === null || username === "Guest") {
                                localStorage.setItem("sharePath", `/showpdf/${postData.id}`);
                              }
                            }}
                            to={`/showpdf/${postData.id}`}
                            className="ms-6 text-primary">
                            {imgIndex + 1 === postData?.awsUrl?.length && "Open"}
                          </Link>
                        )}
                        {images.length > 0 && (
                          <Link
                            to={`/showpdf/${postData.id}`}
                            target="_blank"
                            className="ms-6 text-primary">
                            {imgIndex + 1 === images.length && "Open"}
                          </Link>
                        )}
                      </p>
                      <Link
                        onClick={() => {
                          let token = getToken();
                          if (token === null || username === "Guest") {
                            localStorage.setItem("sharePath", `/showpdf/${postData.id}`);
                          }
                        }}
                        to={`/showpdf/${postData.id}`}
                        target="_blank"
                        className="max_icon">
                        <MaxIcon />
                      </Link>
                    </div>
                  </div>
                ) : postData.postType === "IMAGES" ? (
                  <img
                    className="w-full xl:max-h-[566px] sm:max-h-[400px] max-h-[280px] object-contain"
                    src={Array.isArray(postData?.awsUrl) && postData?.awsUrl[imgIndex]}
                    alt="post img"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="flex items-center sm:pt-8 pt-[9px]">
                {username !== "Guest" && (
                  <div className="sm:mr-12 mr-6 flex items-center">
                    <span
                      onClick={() => {
                        setDisLike(false);
                        setLike(!like);
                        handleLikes(postData.id);
                        incrimentlikes();
                        decrimentlikes();
                      }}
                      className={`inline-block sm:pr-[5px] pr-[3px] like_icon cursor-pointer ${
                        like ? "like" : ""
                      }`}>
                      <LikeIcon />
                    </span>
                    <span className="lg:text-xxl sm:text-lg text-base font-normal ms-1">
                      {Number(postData.likes) + incrimentLike}
                    </span>
                  </div>
                )}
                {username !== "Guest" && (
                  <div className="sm:mr-12 mr-6 flex items-center">
                    <span
                      onClick={() => {
                        setDisLike(!disLike);
                        setLike(false);
                        handleDisLikes(postData.id);
                        decrimentlikes();
                      }}
                      className={`like_icon pr-[3px] cursor-pointer ${disLike ? "like" : ""}`}>
                      <DislikeIcon />
                    </span>
                  </div>
                )}
                <RWebShare
                  data={{
                    text: "Wised",
                    url: `${postData.id}`,
                    title: "post",
                  }}
                  onClick={() => sharePost(id)}>
                  <button className="share_icon cursor-pointer">
                    <ShareIcon />
                  </button>
                </RWebShare>
              </div>
            </div>
          </div>
          <div className="w-1/4 mt-[92px] max-lg:w-full lg:ps-7 hidden lg:block">
            <div className="border-2 border-[#EEEFEF] mb-6 rounded-[11px] overflow-hidden">
              <Link
                onClick={() => {
                  let token = getToken();
                  if (token === null || username === "Guest") {
                    localStorage.setItem(
                      "sharePath",
                      `/ViewByUserprofile/${profileData.userName}`
                    );
                  }
                }}
                to={`/ViewByUserprofile/${profileData.userName}`}>
                <div className="relative max-xl:max-w-[270px] max-xl:mx-auto">
                  <img
                    className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[114px] h-[114px] rounded-[50%]"
                    src={profileData.profileImageUrl || post_as_view_user_pic}
                    alt="user pic"
                  />
                  <img
                    className="w-full h-[289px]"
                    src={profileData.coverImageUrl || post_as_view_user_bg}
                    alt="cover img"
                  />
                </div>
              </Link>
              <div className="text-center pb-3">
                <p className="pb-6 text-xl font-semibold mt-3">{profileData.fullName}</p>
                <p className="pb-1 font-normal fs-base">{profileData?.designation}</p>
                {/* <p className=" font-normal fs-base">not available</p> */}
                <p className="font-semibold fs-base py-6 pb-8">
                  {profileData.followersCount} Followers
                </p>
                {username !== "Guest" && username !== profileData.userName && (
                  <div className="mb-3">
                    <button
                      onClick={() => {
                        setFollow(!follow);
                        if (!follow) {
                          followUser(profileData.userName);
                        } else {
                          unFollowUser(profileData.userName);
                        }
                      }}>
                      <SeeAllButton name={follow ? "Following" : "Follow"} />
                    </button>
                  </div>
                )}
              </div>
            </div>
            {similardata.length > 0 && (
              <div className="border-2 border-[#EEEFEF] overflow-hidden px-2 py-3 rounded-2xl">
                <p className="text-center text-xl font-semibold pb-4">Similar</p>
                <div className="max-h-[700px] overflow-y-auto">
                  {similardata.map((item, index) => {
                    return (
                      <div key={index} className="p-[15px] rounded-[10px] bg-[#F8F8F8] mb-4">
                        <SimilarPosts item={item} />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default PostAsViewByUser;
