import React, { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
import { useParams } from "react-router-dom";
import PdfImage from "../../assets/images/png/pdficon.png";

export default function ShowPdf() {
  const [fileType, setfileType] = useState("");
  const [pdfData, setPdfData] = useState([]);
  const { getUserPostById } = UseCreatePostData();
  const { id } = useParams();
  async function getpostData() {
    let data = await getUserPostById(id);
    setPdfData(data);
  }
  useEffect(() => {
    getpostData();
    if (id) {
      document.body.classList.add("hide_scroll");
    }
  }, [id]);

  useEffect(() => {
    if (Object.keys(pdfData).length !== 0 && pdfData?.awsUrl.length > 0) {
      const fetchFileFromUrl = async (url) => {
        const fileName = url;
        setfileType(fileName.includes(".pdf") ? "pdf" : "");
      };
      fetchFileFromUrl(pdfData?.awsUrl[0]);
    }
  }, [pdfData]);

  return (
    <div className="flex flex-col min-h-screen pdf_div">
      <div
        style={{ boxShadow: "0 4px 25px #ddd6d6" }}
        className="mb-5 sticky top-0 z-[100000] bg-white px-4">
        <div className="flex items-center max-w-[1710px] mx-auto py-3">
          <img className="w-[40px]" src={PdfImage} alt="PdfImage" />
          <p className="text-lg ms-3">PDF</p>
        </div>
      </div>
      <div className="flex-grow">
        {Object.keys(pdfData).length !== 0 && fileType === "pdf" ? (
          <div style={{ position: "relative" }}>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.min.js`}>
              <div className="w-full h-[100vh]">
                <Viewer fileUrl={pdfData?.awsUrl[0]} />
              </div>
            </Worker>
          </div>
        ) : Object.keys(pdfData).length !== 0 && fileType !== "pdf" ? (
          <div className="w-full min-h-screen flex items-center justify-center pdf_div">
            <img className="w-full object-contain max-h-screen" src={pdfData?.awsUrl[0]} alt="" />
          </div>
        ) : null}
      </div>
    </div>
  );
}
