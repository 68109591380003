import React, { useEffect, useMemo } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { UsePDFContext } from "../ContextApis/PDF";
import { useParams } from "react-router-dom";
import PdfImage from "../../assets/images/png/pdficon.png";

const PdfViewer = () => {
  const { pdf, fetchPdf } = UsePDFContext();
  const { id } = useParams();
  useEffect(() => {
    fetchPdf(id);
    if (id) {
      document.body.classList.add("hide_scroll");
    }
  }, []);
  return (
    <div style={{ height: "600px", width: "100%" }} className="flex flex-col min-h-screen pdf_div">
      <div
        style={{ boxShadow: "0 4px 25px #ddd6d6" }}
        className="mb-5 sticky top-0 z-[100000] bg-white px-4">
        <div className="flex items-center max-w-[1710px] mx-auto py-3">
          <img className="w-[40px]" src={PdfImage} alt="PdfImage" />
          <p className="text-lg ms-3">PDF</p>
        </div>
      </div>
      {pdf ? (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.9.359/build/pdf.worker.min.js">
          <div className="w-full h-[100vh]">
            <Viewer fileUrl={URL.createObjectURL(pdf)} />
          </div>
        </Worker>
      ) : (
        <div className="h-screen flex items-center justify-center">
          <p className="text-xl font-semibold">Loading PDF...</p>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
