import React, { useState, useEffect } from "react";
import { CrossIcon, SearchIcon2 } from "../common/Icons";
import { Link } from "react-router-dom";
import { UseNavDataContext } from "../ContextApis/NavDataContext";
import userImg from "../../assets/images/png/user-img2.png";

const Search = ({ closeMenu }) => {
  const [search, setSearch] = useState("");
  const [showGlobalDropdown, setShowGlobalDropdown] = useState(false);
  const { trending, global, getGlobal } = UseNavDataContext();
  const [recentSearchUser, setRecentSearchUser] = useState(
    JSON.parse(localStorage.getItem("recentSeach"))
  );


  const filterTrendingData = Array.isArray(trending)
    ? trending.filter(
        (item) => typeof item === "string" && item.toLowerCase().includes(search.toLowerCase())
      )
    : [];

  const filterRecentData = Array.isArray(recentSearchUser)
    ? recentSearchUser.filter(
        (item) =>
          typeof item && item.fullName && item.fullName.toLowerCase().includes(search.toLowerCase())
      )
    : [];

  useEffect(() => {
    if (search.length > 0) {
      getGlobal(search);
    }
    setRecentSearchUser(JSON.parse(localStorage.getItem("recentSearch")));
  }, [search]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    if (value.length > 0) {
      setShowGlobalDropdown(true);
    } else {
      setShowGlobalDropdown(false);
    }
  };

  function handleStoreSearch(items) {
    let recentSearch = JSON.parse(localStorage.getItem("recentSearch"));
    let getlocal = Array.isArray(recentSearch) ? recentSearch : [];
    let seachArray = [
      ...getlocal,
      {
        fullName: items.fullName,
        userName: items.userName,
        profileImageUrl: items.profileImageUrl,
      },
    ];
    localStorage.setItem("recentSearch", JSON.stringify(seachArray));
    console.log("recent search");
  }

  return (
    <div className="sm:max-w-[586px] sm:min-h-[500px] max-sm:h-full w-full bg-white pt-[34px] pb-[25px] px-[15px] sm:px-[33px] sm:rounded-[16px] md:rounded-[40px] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[60] flex flex-col items-start shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center bg-light-400 justify-between rounded-[32px] px-6 h-[40px] w-full">
          <input
            type="text"
            onChange={handleSearchChange}
            placeholder="Search"
            value={search}
            className="text-base focus:outline-none w-full bg-transparent"
            onFocus={() => setShowGlobalDropdown(search.length > 0)}
            onBlur={() => setTimeout(() => setShowGlobalDropdown(false), 200)}
          />
          <label htmlFor="input">
            <SearchIcon2 />
          </label>
        </div>
        <button onClick={() => closeMenu(false)} className="ms-3 -mr-3 md:hidden">
          <CrossIcon />
        </button>
      </div>
      <div className="h-full sm:h-[400px] overflow-y-scroll w-full mt-4">
        <div>
          <p className="font-semibold text-base mb-3 mt-9">Trending</p>
          {filterTrendingData.length > 0 ? (
            filterTrendingData.map((item, i) => (
              <p
                key={i}
                className="text-tertiary mb-3 cursor-pointer"
                onClick={() => setSearch(item)}>
                {item}
              </p>
            ))
          ) : (
            <p className="text-gray-500">No trending results found</p>
          )}
        </div>

        <div>
          <p className="font-semibold text-base mb-3">Recent</p>
          {filterRecentData.length > 0 ? (
            filterRecentData.map((item, i) => (
              <Link
                to={`/ViewByUserprofile/${item.userName}`}
                key={i}
                className="flex items-center gap-5 my-2">
                <p
                  className="text-tertiary cursor-pointer"
                  onClick={() => {
                    setSearch(item.fullName);
                    closeMenu(false);
                  }}>
                  {item.fullName}
                </p>
                <img
                  className="w-[30px] h-[30px] rounded-full"
                  src={item.profileImageUrl || userImg}
                  alt=""
                />
              </Link>
            ))
          ) : (
            <p className="text-gray-500">No recent results found</p>
          )}
        </div>

        {showGlobalDropdown && search.length > 0 && (
          <div className="absolute top-[80px] bg-white border border-gray shadow px-7 py-2 z-[1] w-[500px] max-w-[500px] rounded-xl max-h-[200px] overflow-y-auto">
            {global.length > 0 ? (
              global.map((item, i) => (
                <Link
                  onClick={() => handleStoreSearch(item)}
                  to={`/ViewByUserprofile/${item.userName}`}
                  key={i}
                  className="flex items-center gap-5 my-2">
                  <p
                    className="text-tertiary cursor-pointer"
                    onClick={() => {
                      setSearch(item.fullName);
                      closeMenu(false);
                    }}>
                    {item.fullName}
                  </p>
                  <img
                    className="w-[30px] h-[30px] rounded-full"
                    src={item.profileImageUrl || userImg}
                    alt=""
                  />
                </Link>
              ))
            ) : (
              <p className="text-gray-500">No Global results found</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Search;
