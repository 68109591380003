import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Spotlight from "../Home/Spotlight";
import SeeAllButton from "../Home/SeeAllButton";
import { CloseIcon, PlusIcon } from "../common/Icons";
import Pic from "../../assets/images/png/Esther.png";
import userImg from "../../assets/images/png/user-img2.png";
import colorsBg from "../../assets/images/png/colorsBg.png";
import dropDownIcon from "../../assets/icons/dropdown-arrow.svg";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import { UseCreatePostData } from "../ContextApis/CreatePostContext";
import DOMPurify from "dompurify";
import { UseTopCreatorsContext } from "../ContextApis/TopCreatorsAndFollow";
import { UseQuestionPaperContext } from "../ContextApis/QuestionPaper";
import { StaggeredGrid, StaggeredGridItem } from "react-staggered-grid";
import { getEducation, getToken } from "../ContextApis/TokenStore";
import showErrorToast from "../ToastError";

export default function Home() {
  const [questionPaper, setQuestionPaper] = useState(null);
  const [examFilterPopup, setExamFilterPopup] = useState(false);
  const [universityFilterPopup, setUniversityFilterPopup] = useState(false);
  const [examContent, setexamContent] = useState(false);
  const [universityContent, setuniversityContent] = useState(true);
  const [checkFollowing, setCheckFollowing] = useState([]);
  const [spacData, setSpacData] = useState([]);
  const [username, setUsername] = useState("");
  const [filtredExam, setFilterdExam] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState({
    universityName: false,
    Semester: false,
    specializationStream: false,
    Exam: false,
  });
  const { searchUniversityData, searchUniversity } = UseProfileContext();
  let educa = getEducation();
  let educa2 = JSON.parse(localStorage.getItem("universityData"));
  let educationDtls = educa2
    ? educa2
    : {
        universityName: educa?.universityName,
        specializationStream: educa?.specializationStream,
        currentSemester: educa?.currentSemester,
      };

  const [universityAllData, setuniversityAllData] = useState({
    universityName: educationDtls?.universityName,
    specializationStream: educationDtls?.specializationStream,
    currentSemester: educationDtls?.currentSemester,
    Exam: "",
  });

  const {
    getWritesUpsData,
    getWritesByExam,
    writesupsData,
    getNotes,
    getExamNotes,
    notesData,
    getRecommendationNewUser,
    recommendationData,
    getRecomadationByExam,
    checkIsFollow,
  } = UseCreatePostData();
  const { allQuestionPapers, getAllQuestionPaper, getExamName, examName, getAllExam, AllExam } =
    UseQuestionPaperContext();
  const { filterpeoplelocation, getPeoplebylocations, unFollowUser, followUser } =
    UseTopCreatorsContext();
  const navigate = useNavigate();

  const showYear = (tabIndex) => {
    setQuestionPaper(questionPaper === tabIndex ? null : tabIndex);
  };
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options).replace(",", "");
  };
  useEffect(() => {
    if (universityAllData.universityName) {
      for (let data in searchUniversityData) {
        if (universityAllData.universityName === data) {
          setSpacData(searchUniversityData[data]);
        }
      }
    }
    if (universityAllData.Exam) {
      getExamName(universityAllData.Exam);
    }
  }, [universityAllData.universityName, universityAllData.Exam]);
  const universityData = [...Object.keys(searchUniversityData)];
  const SpecialisationData = [...spacData];
  const examData = [];
  if (examName) {
    for (let i in examName) {
      for (let a of examName[i]) {
        examData.push(a);
      }
    }
  }
  function handleUniversityData() {
    if (
      universityAllData.universityName &&
      universityAllData.specializationStream &&
      universityAllData.currentSemester
    ) {
      getWritesUpsData(universityAllData);
      getNotes(universityAllData);
      setUniversityFilterPopup(false);
      getRecommendationNewUser(universityAllData);
      getAllQuestionPaper(universityAllData);
      localStorage.setItem("universityData", JSON.stringify(universityAllData));
      setexamContent(false);
      setuniversityContent(true);
    } else {
      showErrorToast("All field required !");
    }
  }

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  async function isFollowed() {
    let id = [];
    if (Array.isArray(filterpeoplelocation)) {
      for (let filterfollow of filterpeoplelocation) {
        let message = await checkIsFollow(filterfollow.userName);
        if (message === "following") {
          id.push(filterfollow.id);
        }
      }
      setCheckFollowing(id);
    }
  }
  useEffect(() => {
    if (universityAllData.universityName?.length > 0) {
      searchUniversity(universityAllData.universityName);
    }
    console.log("university");
  }, [universityAllData.universityName]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (universityFilterPopup || examFilterPopup) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
  const onhandelchange = (e) => {
    const { name, value } = e.target;
    setuniversityAllData({ ...universityAllData, [name]: value });
    setDropdownVisible({
      universityName: name === "universityName" && value.length > 0,
      specializationStream: name === "specializationStream" && value.length > 0,
      Exam: name === "Exam" && value.length > 0,
    });
  };

  const handleDropdownChange = (name, value) => {
    setuniversityAllData({ ...universityAllData, [name]: value });
    setDropdownVisible({ ...dropdownVisible, [name]: false });
  };

  const renderDropdownOptions = (name, options) => {
    return options
      .filter((option) => option.toLowerCase().includes(universityAllData[name].toLowerCase()))
      .map((option, index) => (
        <p
          key={index}
          className="text-sm py-0.5 cursor-pointer hover:bg-gray-100 rounded"
          onClick={() => handleDropdownChange(name, option)}>
          {option}
        </p>
      ));
  };
  const popupRef = useRef();

  function handleClickOutside(event) {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setExamFilterPopup(false);
      setUniversityFilterPopup(false);
    }
  }

  useEffect(() => {
    getPeoplebylocations();
    if (filterpeoplelocation?.length !== 0) {
      isFollowed();
    }
  }, [filterpeoplelocation?.length > 0]);

  function followUnfollow(id) {
    if (checkFollowing.includes(id)) {
      let ids = checkFollowing.filter((item) => item !== id);
      setCheckFollowing(ids);
    } else {
      setCheckFollowing([...checkFollowing, id]);
    }
  }

  const getDateFromFilename = (filename) => {
    const datePart = filename.split("$$")[1].split(".")[0];
    const [month, year] = datePart.split("_");
    return new Date(`${year}-${month}-01`); // Creates a Date object using year and month
  };

  // Sort the question papers by their latest date
  const sortedQuestionPapers = allQuestionPapers.map((folder) => {
    // Sort the files within each folder by date
    if (Array.isArray(allQuestionPapers) && allQuestionPapers.length > 0) {
      folder.contents.items.sort((a, b) => {
        const dateA = getDateFromFilename(a.name);
        const dateB = getDateFromFilename(b.name);
        return dateB - dateA; // Sort in descending order (latest first)
      });
    }

    return folder; // Return the sorted folder
  });

  function handleFilterExam() {
    let filterExamname;
    for (let examkey in examName) {
      if (examName[examkey].includes(universityAllData.Exam)) {
        filterExamname = examkey;
        let exam = { Exam: filterExamname };
        localStorage.setItem("exam", JSON.stringify(exam));
      }
    }
    for (let i of AllExam) {
      for (let a in i.contents.items) {
        if (i.contents.items[a].name === filterExamname) {
          setFilterdExam(i.contents.items[a]);
        }
      }
    }
  }

  useEffect(() => {
    let user = sessionStorage.getItem("userName") || localStorage.getItem("userName");
    setUsername(user);
    getAllExam();
    let contentss = localStorage.getItem("contents");
    if (contentss && contentss === "university") {
      setexamContent(false);
      setuniversityContent(true);
    } else if (contentss && contentss === "exam") {
      setexamContent(true);
      setuniversityContent(false);
    }
  }, []);

  useEffect(() => {
    let filterExamname =
      JSON.parse(localStorage.getItem("exam")) !== null
        ? JSON.parse(localStorage.getItem("exam")).Exam
        : "";

    for (let i of AllExam) {
      for (let a in i.contents.items) {
        if (i.contents.items[a].name === filterExamname) {
          setFilterdExam(i.contents.items[a]);
        }
      }
    }
  }, [AllExam]);

  const sectionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !hasFetched) {
          getAllQuestionPaper(); // Call the function when section is visible
          setHasFetched(true); // Set flag to avoid multiple calls
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed (0.1 means 10% visible)
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current); // Observe the section
    }

    // Cleanup observer when component unmounts
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [hasFetched, getAllQuestionPaper]);

  return (
    <div>
      {(examFilterPopup || universityFilterPopup) && (
        <div
          onClick={handleClickOutside}
          className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full"></div>
      )}
      {examFilterPopup && (
        <div
          ref={popupRef}
          className="max-sm:max-w-[312px] max-w-[376px] w-full bg-white shadow-[0_8px_20px_0_rgba(0,0,0,0.2)] pt-[34px] pb-[25px] px-[15px] rounded-[16px] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[50] flex flex-col items-start">
          <div onClick={() => setExamFilterPopup(false)} className="absolute top-3 right-[14px]">
            <CloseIcon />
          </div>
          <label className="max-md:text-sm max-sm:text-xs" htmlFor="">
            Exam name*
          </label>
          <div className="border border-[#BEC1C3] mt-[6px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer w-full">
            <input
              type="text"
              onChange={onhandelchange}
              className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
              name="Exam"
              placeholder="Exam name"
              value={universityAllData.Exam}
              id="School"
            />
            <img src={dropDownIcon} alt="dropDownIcon" />
            <img src="" alt="" />
            {dropdownVisible.Exam && (
              <div className="max-h-[200px] overflow-y-auto absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                {renderDropdownOptions("Exam", examData)}
              </div>
            )}
          </div>
          <div className="max-sm:text-start max-md:text-center w-full">
            <button
              onClick={() => {
                if (universityAllData.Exam) {
                  getExamNotes(universityAllData);
                  getWritesByExam(universityAllData);
                  getRecomadationByExam(universityAllData);
                  handleFilterExam();
                  localStorage.setItem("contents", "exam");
                  setExamFilterPopup(false);
                  setexamContent(true);
                  setuniversityContent(false);
                } else {
                  showErrorToast("Exam field required !");
                }
              }}
              className="px-4 text-xxs sm:text-sm h-[28px] sm:h-[36px]  border border-[#BEC1C3] rounded-[100px] mt-5">
              Save
            </button>
          </div>
        </div>
      )}
      {universityFilterPopup && (
        <div
          ref={popupRef}
          className=" max-sm:max-w-[312px] max-w-[376px] w-full bg-white  pt-[34px] pb-[25px] px-[15px] rounded-[16px] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[50] flex flex-col items-start shadow-[0_8px_20px_0_rgba(0,0,0,0.2)]">
          <div
            onClick={() => setUniversityFilterPopup(false)}
            className="absolute top-3 right-[14px]">
            <CloseIcon />
          </div>
          <label className="max-md:text-sm max-sm:text-xs" htmlFor="">
            University name/Institute name*
          </label>
          <div className="border border-[#BEC1C3] mt-[6px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer w-full">
            <input
              type="text"
              onChange={onhandelchange}
              className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
              name="universityName"
              placeholder="University name"
              value={universityAllData.universityName}
              id="School"
            />
            <img src={dropDownIcon} alt="dropDownIcon" />
            <img src="" alt="" />
            {dropdownVisible.universityName && (
              <div className="max-h-[200px] overflow-y-auto absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                {renderDropdownOptions("universityName", universityData)}
              </div>
            )}
          </div>
          <label className="mt-4 max-sm:text-xs max-md:text-sm" htmlFor="">
            Specialisation*
          </label>
          <div className="border border-[#BEC1C3] mt-[6px] rounded-[100px] relative flex items-center ps-7 pe-4 cursor-pointer w-full">
            <input
              type="text"
              onChange={onhandelchange}
              className="w-full outline-none h-[40px] max-sm:text-sm bg-transparent"
              name="specializationStream"
              placeholder="Specialisation"
              value={universityAllData.specializationStream}
              id="School"
            />
            <img src={dropDownIcon} alt="dropDownIcon" />
            <img src="" alt="" />
            {dropdownVisible.specializationStream && (
              <div className="max-h-[200px] overflow-y-auto absolute top-[45px] left-0 bg-white border border-gray shadow w-full px-7 py-2 z-[1] rounded-xl dropdown">
                {renderDropdownOptions("specializationStream", SpecialisationData)}
              </div>
            )}
          </div>
          <label className="mt-4 max-sm:text-xs max-md:text-sm" htmlFor="">
            Semester*
          </label>
          <select
            onChange={onhandelchange}
            className={`border border-[#BEC1C3] mt-[6px] rounded-[100px] outline-none  h-[40px] relative flex items-center ps-6 pe-4 cursor-pointer w-[110px] max-sm:text-sm ${
              universityAllData.Semester == "" ? "text-gray-400" : "text-black"
            }`}
            name="currentSemester"
            value={universityAllData.currentSemester}
            id="">
            <option disabled value="">
              Sem
            </option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8 </option>
          </select>
          <div className="max-sm:text-start max-md:text-center w-full">
            <button
              onClick={() => {
                handleUniversityData();
                localStorage.setItem("contents", "university");
              }}
              className="px-4 text-xxs sm:text-sm h-[28px] sm:h-[36px]  border border-[#BEC1C3] rounded-[100px] mt-5">
              Save
            </button>
          </div>
        </div>
      )}
      <div className="max-sm:max-w-[294px] max-md:max-w-[370px] max-w-[480px] bg-[#EEEFEF] rounded-[100px] p-1 sm:p-2 flex text-center mx-auto text-[20px] max-md:text-base max-sm:font-semibold max-sm:text-xs max-sm:mt-3 max-sm:mb-4 my-10">
        <button
          onClick={() => {
            setUniversityFilterPopup(true);
          }}
          className={`h-[25px] sm:h-[32px] lg:h-[40px] w-full rounded-[100px]  ${
            universityContent ? "bg-primary text-white" : "text-black"
          }`}>
          University
        </button>
        <button
          onClick={() => {
            setExamFilterPopup(true);
          }}
          className={`h-[25px] sm:h-[32px] lg:h-[40px] w-full rounded-[100px]  ${
            examContent ? "bg-primary text-white" : "text-black"
          }`}>
          Exams
        </button>
      </div>

      {Array.isArray(recommendationData) && recommendationData.length > 0 && (
        <div>
          <p className="text-center sm:mt-10 text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold">
            Recommendations
          </p>
          <section className="mt-4 sm:mt-10 px-6 max-sm:px-4">
            <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 gap-y-7 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-x-4 lg:mb-10 sm:mb-12 mb-4 max-sm:gap-y-4">
              {recommendationData.slice(0, 9).map((item, index) => {
                return (
                  <div key={index}>
                    <Link to={`/home/PostAsViewByUser/${"id"}`}>
                      <Spotlight data={item} />
                    </Link>
                  </div>
                );
              })}
            </div>
            <NavLink to="/streamrecommondations">
              <div className=" text-center lg:mb-10 sm:mb-12 mb-4">
                <SeeAllButton name={"See All"} />
              </div>
            </NavLink>
            <hr />
          </section>
        </div>
      )}
      {Array.isArray(notesData) && notesData.length > 0 && (
        <section className="mt-4 sm:mt-10 px-6 max-sm:px-4">
          <p className="text-center mb-4 sm:mb-10 text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold">
            Notes
          </p>
          <div className="container mx-auto grid grid-rows-1 sm:grid-cols-3 grid-cols-2 xl:gap-x-[77px] sm:gap-x-[42px] gap-y-7 gap-x-4 lg:mb-10 sm:mb-12 mb-4 max-sm:gap-y-4">
            {notesData.slice(0, 9).map((item, index) => {
              return (
                <div key={index}>
                  <Link
                    target={item.url ? "_blank" : ""}
                    onClick={() => {
                      let token = getToken();
                      if (token === null || (username === "Guest" && !item.user)) {
                        localStorage.setItem("sharePath", `/showpdf2/${item.id}`);
                      }
                    }}
                    to={item.user ? `/home/PostAsViewByUser/${item.id}` : `/showpdf2/${item.id}`}>
                    <Spotlight data={item} />
                  </Link>
                </div>
              );
            })}
          </div>
          <NavLink to="/streamnotespage">
            <div className=" text-center lg:mb-10 sm:mb-12 mb-4">
              <SeeAllButton name={"See All"} />
            </div>
          </NavLink>
          <hr />
        </section>
      )}
      <section className="px-6 max-sm:px-4" ref={sectionRef}>
        {universityContent && Array.isArray(allQuestionPapers) && allQuestionPapers.length > 0 && (
          <>
            <div className="container mx-auto mt-4 sm:my-10 lg:mb-10 sm:mb-12 mb-4">
              <p className="text-center mb-4 sm:mb-10 text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold">
                Question papers
              </p>
              <StaggeredGrid
                // columnWidth={} // width of each column
                columns={windowWidth >= 1536 ? 3 : windowWidth > 640 ? 2 : 1} // 0 would adjust columns
                alignment={1} // 0 : start , 1 : center , 2 : end
                horizontalGap={
                  windowWidth > 1024 ? 80 : windowWidth > 768 ? 50 : windowWidth > 624 ? 40 : 20
                }
                verticalGap={windowWidth > 768 ? 40 : 20} // horizontal gap between grid items
                fitHorizontalGap={true}>
                {sortedQuestionPapers.slice(0, 6).map((item, index) => {
                  return (
                    <StaggeredGridItem key={index} index={index}>
                      <div className="flex items-center justify-between flex-col ">
                        <div
                          onClick={() => {
                            showYear(index);
                            // getPdf(item.name);
                          }}
                          style={{ boxShadow: "0px 8px 20px 0px #2F327D1A" }}
                          className="flex items-center justify-between w-full px-6 md:px-[30px] xl:px-[40px] xxl:px-[54px] max-md:rounded-[6px] rounded-[16px] h-[80px] md:h-[100px] lg:h-[160px]">
                          <div className="overflow-hidden">
                            <p className="text-xl max-xl:text-[20px] max-lg:text-[18px] max-md:text-[14px] lg:font-medium question_paper_name relative">
                              {item.name}
                            </p>
                          </div>
                          <button className="add_qus_btn ms-2">
                            <PlusIcon />
                          </button>
                        </div>
                        {questionPaper === index && (
                          <div
                            style={{ boxShadow: "0px 8px 20px 0px #2F327D1A" }}
                            className="flex flex-col gap-[42px] max-md:gap-[20px] max-md:py-[20px] py-[20px] justify-center items-center w-full max-md:rounded-[6px] rounded-[16px] md:mt-[30px] mt-[18px] px-2">
                            {item.contents.items.map((items, i) => {
                              return (
                                <div className="overflow-hidden bg-[#EBF2FF] rounded-[16px] ">
                                  <Link
                                    target="_blank"
                                    key={i}
                                    onClick={() => {
                                      let token = getToken();
                                      if (token === null || username === "Guest") {
                                        localStorage.setItem("sharePath", `/showpdf2/${items.id}`);
                                      }
                                    }}
                                    to={`/showpdf2/${items.id}`}
                                    className="whitespace-nowrap text-[16px] max-md:text-sm max-sm:text-xs px-4 py-3 max-md:p-2 cursor-pointer relative inline-block">
                                    {items.name.split("$$")[1].split(".")[0].split("_")[0]}{" "}
                                    {items.name.split("$$")[1].split(".")[0].split("_")[1]}
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </StaggeredGridItem>
                  );
                })}
              </StaggeredGrid>
            </div>
            <div className="text-center">
              <button
                onClick={() => navigate("/questionpaper")}
                className="text-center lg:mb-10 sm:mb-12 mb-4 ">
                <SeeAllButton name={"See All"} />
              </button>
            </div>
            <hr />
          </>
        )}
      </section>
      {!Array.isArray(filtredExam) && examContent && (
        <section className="px-6 max-sm:px-4">
          <div className="container mx-auto mt-4 sm:my-10 lg:mb-10 sm:mb-12 mb-4">
            <p className="text-center sm:mt-10 text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold mb-4">
              {filtredExam.name.split("_").join(" ")}
            </p>
            {filtredExam.contents.items[0].name.includes("$") ? (
              <StaggeredGrid
                columns={windowWidth >= 1536 ? 3 : windowWidth > 640 ? 2 : 1}
                alignment={1}
                horizontalGap={
                  windowWidth > 1024 ? 80 : windowWidth > 768 ? 50 : windowWidth > 624 ? 40 : 20
                }
                verticalGap={windowWidth > 768 ? 40 : 20}
                fitHorizontalGap={true}>
                {filtredExam.contents.items
                  .slice(0, windowWidth >= 1536 ? 3 : 4)
                  .map((item, ind) => {
                    return (
                      <StaggeredGridItem key={ind} index={ind}>
                        <Link
                          target="_blank"
                          onClick={() => {
                            let token = getToken();
                            if (token === null || username === "Guest") {
                              localStorage.setItem("sharePath", `/showpdf2/${item.id}`);
                            }
                          }}
                          to={`/showpdf2/${item.id}`}
                          className="flex items-center justify-between flex-col ">
                          <div
                            style={{ boxShadow: "0px 8px 20px 0px #2F327D1A" }}
                            className="flex items-center justify-between w-full px-6 md:px-[30px] xl:px-[40px] xxl:px-[54px] max-md:rounded-[6px] rounded-[16px] h-[80px] md:h-[100px] lg:h-[160px]">
                            <div className="overflow-hidden">
                              <p className="text-xl max-xl:text-[20px] max-lg:text-[18px] max-md:text-[14px] lg:font-medium question_paper_name relative">
                                {item.name?.split("$$")[1]?.split(".")[0]?.split("_")[0]}{" "}
                                {item.name?.split("$$")[1]?.split(".")[0]?.split("_")[1]}
                              </p>
                            </div>
                            <button className="add_qus_btn ms-2">
                              <PlusIcon />
                            </button>
                          </div>
                        </Link>
                      </StaggeredGridItem>
                    );
                  })}
              </StaggeredGrid>
            ) : (
              filtredExam.contents.items.map((item, ind) => {
                return (
                  <div key={ind}>
                    {!item.name.includes("$") && (
                      <p className="text-center text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold mb-4">
                        {item.name}
                      </p>
                    )}
                    <div>
                      {item.contents && item.contents.items[0].name.includes("$") ? (
                        <StaggeredGrid
                          columns={windowWidth >= 1536 ? 3 : windowWidth > 640 ? 2 : 1}
                          alignment={1}
                          horizontalGap={
                            windowWidth > 1024
                              ? 80
                              : windowWidth > 768
                              ? 50
                              : windowWidth > 624
                              ? 40
                              : 20
                          }
                          verticalGap={windowWidth > 768 ? 40 : 20}
                          fitHorizontalGap={true}>
                          {item.contents.items
                            .slice(0, windowWidth >= 1536 ? 3 : 4)
                            .map((items, index) => {
                              return (
                                <StaggeredGridItem key={index} index={index}>
                                  <Link
                                    target="_blank"
                                    onClick={() => {
                                      let token = getToken();
                                      if (token === null || username === "Guest") {
                                        localStorage.setItem("sharePath", `/showpdf2/${items.id}`);
                                      }
                                    }}
                                    to={`/showpdf2/${items.id}`}
                                    className="flex items-center justify-between flex-col ">
                                    <div
                                      style={{ boxShadow: "0px 8px 20px 0px #2F327D1A" }}
                                      className="flex items-center justify-between w-full px-6 md:px-[30px] xl:px-[40px] xxl:px-[54px] max-md:rounded-[6px] rounded-[16px] h-[80px] md:h-[100px] lg:h-[160px]">
                                      <div className="overflow-hidden">
                                        <p className="text-xl max-xl:text-[20px] max-lg:text-[18px] max-md:text-[14px] lg:font-medium question_paper_name relative">
                                          {items.name?.split("$$")[1]?.split(".")[0]?.split("_")[0]}{" "}
                                          {items.name?.split("$$")[1]?.split(".")[0]?.split("_")[1]}
                                        </p>
                                      </div>
                                      <button className="add_qus_btn ms-2">
                                        <PlusIcon />
                                      </button>
                                    </div>
                                  </Link>
                                </StaggeredGridItem>
                              );
                            })}
                        </StaggeredGrid>
                      ) : (
                        item.contents &&
                        item.contents.items.slice(0, 4).map((items, index4) => {
                          return (
                            <div key={index4}>
                              <p className="text-center text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold mb-4">
                                {items.name}
                              </p>
                              {items.contents && items.contents.items[0].name.includes("$") ? (
                                <StaggeredGrid
                                  columns={windowWidth >= 1536 ? 3 : windowWidth > 640 ? 2 : 1}
                                  alignment={1}
                                  horizontalGap={
                                    windowWidth > 1024
                                      ? 80
                                      : windowWidth > 768
                                      ? 50
                                      : windowWidth > 624
                                      ? 40
                                      : 20
                                  }
                                  verticalGap={windowWidth > 768 ? 40 : 20}
                                  fitHorizontalGap={true}>
                                  {items.contents.items
                                    .slice(0, windowWidth >= 1536 ? 3 : 4)
                                    .map((items5, index2) => {
                                      return (
                                        <StaggeredGridItem key={index2} index={index2}>
                                          <div className="flex items-center justify-between flex-col ">
                                            <Link
                                              target="_blank"
                                              onClick={() => {
                                                let token = getToken();
                                                if (token === null || username === "Guest") {
                                                  localStorage.setItem(
                                                    "sharePath",
                                                    `/showpdf2/${items5.id}`
                                                  );
                                                }
                                              }}
                                              to={`/showpdf2/${items5.id}`}
                                              style={{ boxShadow: "0px 8px 20px 0px #2F327D1A" }}
                                              className="flex items-center justify-between w-full px-6 md:px-[30px] xl:px-[40px] xxl:px-[54px] max-md:rounded-[6px] rounded-[16px] h-[80px] md:h-[100px] lg:h-[160px]">
                                              <div className="overflow-hidden">
                                                <p className="text-xl max-xl:text-[20px] max-lg:text-[18px] max-md:text-[14px] lg:font-medium question_paper_name relative">
                                                  {
                                                    items5.name
                                                      ?.split("$$")[1]
                                                      ?.split(".")[0]
                                                      ?.split("_")[0]
                                                  }{" "}
                                                  {
                                                    items5.name
                                                      ?.split("$$")[1]
                                                      ?.split(".")[0]
                                                      ?.split("_")[1]
                                                  }
                                                </p>
                                              </div>
                                              <button className="add_qus_btn ms-2">
                                                <PlusIcon />
                                              </button>
                                            </Link>
                                          </div>
                                        </StaggeredGridItem>
                                      );
                                    })}
                                </StaggeredGrid>
                              ) : (
                                items.contents &&
                                items.contents.items((itemss, index3) => {
                                  return (
                                    <div key={index3}>
                                      <p className="text-center text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold mb-4">
                                        {itemss.name}
                                      </p>
                                      {itemss.contents &&
                                      itemss.contents.items[0].name.includes("$") ? (
                                        <StaggeredGrid
                                          columns={
                                            windowWidth >= 1536 ? 3 : windowWidth > 640 ? 2 : 1
                                          }
                                          alignment={1}
                                          horizontalGap={
                                            windowWidth > 1024
                                              ? 80
                                              : windowWidth > 768
                                              ? 50
                                              : windowWidth > 624
                                              ? 40
                                              : 20
                                          }
                                          verticalGap={windowWidth > 768 ? 40 : 20}
                                          fitHorizontalGap={true}>
                                          {itemss.contents.items
                                            .slice(0, windowWidth >= 1536 ? 3 : 4)
                                            .map((items8, index8) => {
                                              return (
                                                <StaggeredGridItem key={index8} index={index8}>
                                                  <div className="flex items-center justify-between flex-col ">
                                                    <Link
                                                      target="_blank"
                                                      onClick={() => {
                                                        let token = getToken();
                                                        if (
                                                          token === null ||
                                                          username === "Guest"
                                                        ) {
                                                          localStorage.setItem(
                                                            "sharePath",
                                                            `/showpdf2/${items8.id}`
                                                          );
                                                        }
                                                      }}
                                                      to={`/showpdf2/${items8.id}`}
                                                      style={{
                                                        boxShadow: "0px 8px 20px 0px #2F327D1A",
                                                      }}
                                                      className="flex items-center justify-between w-full px-6 md:px-[30px] xl:px-[40px] xxl:px-[54px] max-md:rounded-[6px] rounded-[16px] h-[80px] md:h-[100px] lg:h-[160px]">
                                                      <div className="overflow-hidden">
                                                        <p className="text-xl max-xl:text-[20px] max-lg:text-[18px] max-md:text-[14px] lg:font-medium question_paper_name relative">
                                                          {
                                                            items8.name
                                                              ?.split("$$")[1]
                                                              ?.split(".")[0]
                                                              ?.split("_")[0]
                                                          }{" "}
                                                          {
                                                            items8.name
                                                              ?.split("$$")[1]
                                                              ?.split(".")[0]
                                                              ?.split("_")[1]
                                                          }
                                                        </p>
                                                      </div>
                                                      <button className="add_qus_btn ms-2">
                                                        <PlusIcon />
                                                      </button>
                                                    </Link>
                                                  </div>
                                                </StaggeredGridItem>
                                              );
                                            })}
                                        </StaggeredGrid>
                                      ) : null}
                                    </div>
                                  );
                                })
                              )}
                            </div>
                          );
                        })
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
          <div className="text-center">
            <button
              onClick={() => navigate("/exams")}
              className="text-center lg:mb-10 sm:mb-12 mb-4 ">
              <SeeAllButton name={"See All"} />
            </button>
          </div>
          <hr />
        </section>
      )}
      {Array.isArray(filterpeoplelocation) && filterpeoplelocation.length > 0 && (
        <section className="px-6 max-sm:px-4">
          <div className="container m-auto lg:mb-10 sm:mb-12 mb-4">
            <p className="text-center my-5 sm:mt-10 text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold">
              Recommended people
            </p>
            <div className="flex flex-wrap gap-x-[84px] gap-y-7 max-sm:gap-y-4 max-xl:gap-x-[65px] max-md:gap-x-[45px] max-sm:gap-x-[15px] sm:mt-10">
              {Array.isArray(filterpeoplelocation) &&
                filterpeoplelocation.length > 0 &&
                filterpeoplelocation.slice(0, 9).map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{ boxShadow: "0px 20.77px 51.93px 0px #2F327D1A" }}
                      className="people_card bg-[#ffffff] rounded-[4px] md:rounded-[16px] overflow-hidden">
                      <Link to={`/ViewByUserprofile/${item.userName}`}>
                        <div className="relative rounded-[16px]">
                          <img
                            className="w-full max-sm:h-[156px] max-md:h-[165px] [400px]"
                            src={colorsBg}
                            alt="colorsBg"
                          />
                          <img
                            className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] max-md:w-[125px] max-md:h-[125px] max-lg:w-[160px] max-lg:h-[160px] max-xl:w-[200px] max-xl:h-[200px] max-2xl:w-[240px] max-2xl:h-[240px] max-sm:p-3 rounded-full object-cover object-top w-[324px] h-[324px]"
                            src={item.profileImageUrl || userImg}
                            alt="peoples"
                          />
                        </div>
                      </Link>
                      <div className="flex flex-col items-center justify-between pt-[21px] px-[12px] pb-[24px] text-center max-sm:px-4 max-md:px-7 max-md:pt-2 max-sm:py-2 max-md:pb-[10px]">
                        <div>
                          <p className="text-xl max-md:text-base max-sm:text-sm font-medium lg:font-semibold">
                            {item.fullName}
                          </p>
                          <p className="text-lg max-sm:text-xxs max-md:text-xs py-5 max-md:py-[6px] max-sm:p-1 max-lg:py-3">
                            {item.designation || "N/A"}
                          </p>
                        </div>
                        <button
                          onClick={() => {
                            if (checkFollowing.includes(item.id)) {
                              unFollowUser(item.userName);
                            } else {
                              followUser(item.userName);
                            }
                            followUnfollow(item.id);
                          }}
                          className={`text-base max-md:text-sm h-[28px]  px-[41px] md:h-[60px] rounded-[100px] max-md:px-2 max-sm:mt-1 ${
                            !checkFollowing.includes(item.id)
                              ? "bg-primary text-white"
                              : "border border-[#BEC1C3]"
                          }`}>
                          {checkFollowing.includes(item.id) ? "Following" : "Follow"}
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <NavLink to="/streamrecommendedpeople">
            <div className=" text-center lg:mb-10 sm:mb-12 mb-4">
              <SeeAllButton name={"See All"} />
            </div>
          </NavLink>
        </section>
      )}
      <hr />
      {Array.isArray(writesupsData) && writesupsData.length > 0 && (
        <section className="px-6 max-sm:px-4">
          <div className="container m-auto lg:mb-10 sm:mb-12 mb-4">
            <p className="text-center my-5 sm:mt-10 text-[20px] max-md:text-[20px] max-sm:text-[16px] font-semibold">
              Write-Ups
            </p>
            <div className="flex items-center justify-between sm:mt-10 gap-[40px] max-md:gap-12 max-sm:gap-4 flex-wrap">
              {Array.isArray(writesupsData) &&
                writesupsData.length !== 0 &&
                writesupsData.splice(0, 4).map((items, index) => {
                  const sanitizedHtml = DOMPurify.sanitize(items.description);
                  return (
                    <Link
                      to={`/home/PostAsViewByUser/${items.id}`}
                      key={index}
                      className="border broder-[#BEC1C3] p-10 max-lg:p-7 max-md:p-4 max-sm:py-[13px] max-sm:px-[23px] write_ups_card rounded-[6px]">
                      <span className="text-base max-md:text-xs font-normal leading-6  bg-[#F8F8F8] min-w-[200px] text-center py-1 rounded-[10px] px-6">
                        # Tips and tricks
                      </span>
                      <div
                        className="text-base max-md:text-xs mt-[10px]"
                        dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
                      />
                      <div className="flex items-center mt-[15px]">
                        <img
                          className="min-w-[36px] max-w-[36px] h-[36px] rounded-[50%]"
                          src={items.user ? items.user.profileImageUrl : Pic}
                          alt=""
                        />
                        <div className="ms-[10px]">
                          <p className="text-base max-md:text-xs font-semibold lg:font-normal">
                            {items.user.fullName}
                          </p>
                          <p className="text-base max-md:text-xxs font-normal text-[#BEC1C3]">
                            {formatDate(items.updatedAt)}
                          </p>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
          </div>
          <NavLink to="/streamwriteups">
            <div className=" text-center lg:mb-10 sm:mb-12 mb-4">
              <SeeAllButton name={"See All"} />
            </div>
          </NavLink>
        </section>
      )}
    </div>
  );
}
