import React, { useEffect, useRef, useState } from "react";
import logo from "../../assets/images/png/Logo.png";
import Nav_profile_img from "../../assets/images/png/nav_profile_img.png";
import search_icon_mobile_view from "../../assets/images/svg/search_icon_mobile_view.svg";
import hamburger_profile_icon from "../../assets/images/svg/profile3.svg";
import backward_arrow from "../../assets/images/svg/backward_arrow.svg";
import {
  CloseIcon,
  Create_icon,
  Home_icon,
  Notification_icon,
  Search_icon,
  Setting_icon,
  ShieldIcon,
  Stream_icon,
} from "./Icons";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Notification from "../popup/Notification";
import Search from "../popup/Search";
import CreatePost from "../Create/CreatePost";
import { toast } from "react-toastify";
import { UseNotificationsContext } from "../ContextApis/NotificationsContext";
import { UseProfileContext } from "../ContextApis/ProfileContext";
import { getToken } from "../ContextApis/TokenStore";

const NavBar = () => {
  // State variables
  // const [isSearching, setIsSearching] = useState(false);
  const [settingsPopup, setSettingsPopup] = useState(false);
  const [searchpopup, setSearchpopup] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [notificationCount, setnotificationCount] = useState(0);
  const { notification } = UseNotificationsContext();
  const { profileData } = UseProfileContext();
  useEffect(() => {
    let user = sessionStorage.getItem("userName");
    setUsername(user);
  });
  useEffect(() => {
    let count = notification.filter((item) => item.notificationStatus == "UNREAD");
    if (count.length > 0) {
      setnotificationCount(count.length);
    } else {
      setnotificationCount(0);
    }
  }, [notification]);
  // Refs

  const popupRef = useRef(null);
  // Close popup when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupVisible(false);
      }
    }
    if (settingsPopup || searchpopup || isPopupVisible) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    if (isPopupVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPopupVisible, settingsPopup, searchpopup]);
  const navigate = useNavigate();
  const notifications = async (event) => {
    event.preventDefault();
    if (username !== "Guest") {
      setSettingsPopup(true);
    } else {
      navigate("/login");
      sessionStorage.clear();
      localStorage.clear();
      toast.info("Please log in to access this feature.");
    }
  };
  const handleNavigation = (event) => {
    event.preventDefault();
    if (username === "Guest") {
      toast.info("Please log in to access this feature.");
      navigate("/login");
      sessionStorage.clear();
      localStorage.clear();
    } else {
      navigate("/settings/accounts-settings");
    }
  };
  const handleprofile = (event) => {
    event.preventDefault();
    if (username === "Guest") {
      toast.info("Please log in to access this feature.");
      navigate("/login");
      sessionStorage.clear();
      localStorage.clear();
    } else {
      navigate("/profile");
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  // Location hook
  const location = useLocation();

  return (
    <div>
      {(isPopupVisible || isOpen) && (
        <div className="backdrop-blur-[10px] z-[50] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] body_overlay w-full max-sm:overflow-auto"></div>
      )}
      {isPopupVisible && (
        <div ref={popupRef}>
          <CreatePost value={setIsPopupVisible} popupVisible={isPopupVisible} />
        </div>
      )}
      {(settingsPopup || searchpopup) && (
        <div
          onClick={() => {
            setSearchpopup(false);
            setSettingsPopup(false);
          }}
          className="backdrop-blur-[10px] z-50 fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] h-[100vh] w-full"></div>
      )}
      {settingsPopup && <Notification className="z-40" setSettingsPopup={setSettingsPopup} />}
      {searchpopup && (
        <>
          <div className="absolute top-3 right-[14px] cursor-pointer">
            <CloseIcon />
            <Search className="z-[100]" closeMenu={setSearchpopup} isCose={searchpopup} />
          </div>
        </>
      )}
      <div className="bg-white">
        <nav className="w-full mx-auto lg:flex bg-light-400  lg:py-2 px-7  lg:justify-between">
          <div className="lg:flex hidden  items-center gap-10">
            <Link to="/home">
              <img src={logo} alt="" />
            </Link>
            <div
              onClick={() => setSearchpopup(true)}
              className="w-[260px] xl:w-[360px] flex bg-white justify-between rounded-[32px] ps-5">
              <input
                type="text"
                name=""
                id="input"
                placeholder="Search"
                className="focus:outline-none w-full"
                readOnly
              />
              <label htmlFor="input">
                <Search_icon />
              </label>
            </div>
          </div>

          <div className="navbar_link_size flex xl:space-x-4 justify-between px-5 lg:pe-0 max-sm:justify-between max-lg:justify-evenly max-lg:items-end  lg:items-end  lg:gap-4 mobile_navbar_bg z-40 max-lg:fixed max-lg:w-full left-0 bottom-0 max-lg:shadow-[0_0px_10px_0_rgba(0,0,0,0.3)]">
            <NavLink
              to="/home"
              className={`nav_links ${location.pathname.startsWith("/home") ? "active" : null}`}>
              <div className="flex lg:px-4 max-lg:py-[6.4px] flex-col items-center lg:pb-1 lg:border-b-[2px] border-transparent">
                <Home_icon />
                <p className=" text-xxs  lg:text-sm lg:mt-0.5">Home</p>
              </div>
            </NavLink>

            <NavLink
              to="/streamhome"
              className={`nav_links storke_link ${
                location.pathname === "/streamhome" ||
                location.pathname === "/streamrecommondations" ||
                location.pathname === "/streamnotespage" ||
                location.pathname === "/streamwriteups" ||
                location.pathname === "/streamrecommendedpeople" ||
                location.pathname === "/questionpaper"
                  ? "active"
                  : null
              }`}>
              <div className="flex lg:px-4 max-lg:py-[6.4px] flex-col  items-center lg:border-b-[2px] border-transparent lg:pb-1">
                <Stream_icon />
                <p className=" text-xxs  lg:text-sm  lg:mt-0.5  whitespace-nowrap">By stream</p>
              </div>
            </NavLink>

            {username !== "Guest" && (
              <NavLink onClick={() => setIsPopupVisible(true)}>
                <div className="flex lg:px-4 max-lg:py-[6.4px] flex-col  items-center lg:border-b-[2px] border-transparent lg:pb-1">
                  <Create_icon />
                  <p className=" text-xxs  lg:text-sm lg:mt-0.5  ">Create</p>
                </div>
              </NavLink>
            )}
            <a
              href="#"
              onClick={(e) => {
                handleNavigation(e);
                let token = getToken();
                if (token === null || username === "Guest") {
                  sessionStorage.setItem("sharePath", "/settings");
                }
              }}
              className={`nav_links  d_none ${
                location.pathname.startsWith("/settings") ? "active" : null
              }`}>
              <div className=" lg:px-4 max-lg:py-[6.4px] flex-col  items-center lg:border-b-[2px] border-transparent lg:pb-1  lg:flex">
                <Setting_icon />
                <p className=" text-xxs lg:text-sm lg:mt-0.5">Settings</p>
              </div>
            </a>
            <NavLink>
              <div
                onClick={notifications}
                className="flex lg:px-4 max-lg:py-[6.4px] flex-col  items-center lg:border-b-[2px] border-transparent lg:pb-1 ">
                <div className="relative">
                  <Notification_icon />
                  {notificationCount > 0 && (
                    <p className="text-[6px] lg:text-base absolute w-[14px] h-[14px]  lg:w-[20px] lg:h-[20px] rounded-full bg-secondary lg:right-0 -right-1 bottom-2 lg:bottom-4 flex items-center justify-center text-white">
                      {notificationCount}
                    </p>
                  )}
                </div>
                <p className=" text-xxs  lg:text-sm  lg:mt-0.5  ">Notifications</p>
              </div>
            </NavLink>
            {/* <NavLink
              to="/profile" */}
            <a
              href="#"
              onClick={(e) => {
                handleprofile(e);
                let token = getToken();
                if (token === null || username === "Guest") {
                  sessionStorage.setItem("sharePath", "/profile");
                }
              }}
              className={`nav_links hidden lg:block ${
                location.pathname.startsWith("/profile") ? "active" : null
              }`}>
              <img
                className="min-w-[49px] max-w-[49px] h-[49px] rounded-full d_none lg:mb-[10px]"
                src={profileData?.profileImageUrl || Nav_profile_img}
                alt=""
              />
              {/* </NavLink> */}
            </a>
          </div>

          {isOpen && (
            <div className=" flex lg:hidden max-lg:flex-col max-lg:justify-between max-lg:items-center lg:items-end z_51 gap-6 lg:gap-4 max-lg:fixed max-lg:bg-white nav_mobile_height  min-h-screen w-[60%] left-0  top-[0px] ">
              <img
                onClick={toggleMenu}
                className="absolute top-[30px] right-[30px]"
                src={backward_arrow}
                alt=""
              />
              <div className="flex items-center flex-col mt-[60px]">
                <img
                  className="w-[68px] h-[68px] rounded-full"
                  src={profileData?.profileImageUrl || hamburger_profile_icon}
                  alt="hamburger_profile_icon"
                />
                <p className="text-base font-semibold mt-4 mb-0.5">{profileData?.fullName}</p>
                <p className="text-base max-sm:text-sm px-3 text-center">
                  {profileData?.designation}
                </p>
              </div>
              <div>
                {username !== "Guest" && (
                  <NavLink
                    onClick={() => {
                      setIsOpen(false);
                      toggleMenu();
                    }}
                    to="/profile"
                    className="flex items-center py-[19.42px]">
                    <img
                      className="max-lg:w-[23.92px]"
                      src={hamburger_profile_icon}
                      alt="hamburger_profile_icon"
                    />
                    <p className="text-sm ms-1.5 ps-px">Profile</p>
                  </NavLink>
                )}
                <NavLink
                  onClick={() => {
                    setIsOpen(false);
                    toggleMenu();
                  }}
                  to="/settings"
                  className="flex items-center py-[19.42px]">
                  <Setting_icon />
                  <p className="text-sm ms-1.5 ps-px">Settings</p>
                </NavLink>
                <NavLink
                  onClick={() => {
                    setIsOpen(false);
                    toggleMenu();
                  }}
                  to="/settings/helps"
                  className="flex items-center py-[19.42px]">
                  <ShieldIcon />
                  <p className="text-sm ms-1.5 ps-px">Help</p>
                </NavLink>
              </div>
              <div className="self-start ps-8 mb-3">
                <p className="text-xxs"> ©2024 by Wised Inc.</p>
                <p className="text-xxs">All Rights Reserved by Wised Inc.</p>
              </div>
            </div>
          )}
        </nav>
        <nav className="container mx-auto sm:py-6 h-[60px] lg:hidden flex items-center justify-between bg-white sm:px-4 lg:px-0 px max-sm:px-[16px]">
          <div
            className="hamburger_menu w-[12px] h-[10px] flex flex-col justify-between "
            onClick={toggleMenu}>
            <span className="inline-block w-full bg-black h-[2px] rounded-sm"></span>
            <span className="inline-block w-full bg-black h-[2px] rounded-sm"></span>
            <span className="inline-block w-full bg-black h-[2px] rounded-sm"></span>
          </div>
          <Link to="/home">
            <img className="w-[46px] transition-all  sm:w-[68px]" src={logo} alt="logo" />
          </Link>

          <div className="flex">
            <button onClick={() => setSearchpopup(true)}>
              <label htmlFor="main_search">
                <img
                  className="w-[20px] h-[20px]"
                  src={search_icon_mobile_view}
                  alt="search_icon_mobile_view"
                />
              </label>
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
