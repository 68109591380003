import { createContext, useContext, useEffect, useState } from "react";
import Loader from "../Loader";
import { path } from "../common/Api";
const NavDataContext = createContext();

export const NavDataProvider = ({ children }) => {
  const [trending, setTrending] = useState([]);
  const [global, setGlobal] = useState([]);
  const [loading, setLoading] = useState(false);

  // Helper function to get token
  let token = null;
  let localTokenStore = JSON.parse(localStorage.getItem("tokens"));
  let sessionTokenStore = JSON.parse(sessionStorage.getItem("tokens"));
  if (localTokenStore === null) {
    token = sessionTokenStore;
  } else {
    token = localTokenStore;
  }
  // Get Trending
  async function getTrending() {
    setLoading(true);
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}search/trending`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Trending settings");
      }
      const data = await response.json();
      const searchedQueries = data.searchedQueries || [];
      setTrending(searchedQueries);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }

  // Global
  async function getGlobal(query) {
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}search/?query=${query}`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch Recent data");
      }
      const data = await response.json();
      setGlobal(data.users);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const [username, setUsername] = useState(
    localStorage.getItem("userName") || sessionStorage.getItem("userName")
  );
  useEffect(() => {
    if (username) {
      getTrending();
      setUsername(localStorage.getItem("userName"));
    }
  }, [username]);

  return (
    <NavDataContext.Provider value={{ trending, loading, global, getTrending, getGlobal }}>
      {loading && <Loader />}
      {children}
    </NavDataContext.Provider>
  );
};

export const UseNavDataContext = () => {
  return useContext(NavDataContext);
};
