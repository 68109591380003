import { createContext, useContext, useEffect, useState } from "react";
import Loader from "../Loader";
import { path } from "../common/Api";
const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const [notification, setNotification] = useState([]);
  const [loading, setLoading] = useState(false);

  // Helper function to get token
  let token = null;
  let localTokenStore = JSON.parse(localStorage.getItem("tokens"));
  let sessionTokenStore = JSON.parse(sessionStorage.getItem("tokens"));
  if (localTokenStore === null) {
    token = sessionTokenStore;
  } else {
    token = localTokenStore;
  }
  // Get Notification Settings
  async function getNotification() {
    setLoading(true);

    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      // Make the API call
      const response = await fetch(`${path}notifications/get-all-notifications`, requestOptions);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch notification settings");
      }
      const data = await response.json();
      setNotification(data.notifications);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  }
  // Mark Notification as Read
  async function markNotificationAsRead(notificationId) {
    try {
      if (!token.accessToken) {
        throw new Error("No access token found");
      }
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.accessToken}`,
        },
      };
      const response = await fetch(
        `${path}notifications/mark-notification-as-read?notificationId=${notificationId}`,
        requestOptions
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to mark notification as read");
      }
      setNotification((prevNotifications) =>
        prevNotifications.map((notif) =>
          notif.id === notificationId ? { ...notif, notificationStatus: "READ" } : notif
        )
      );
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const [username, setUsername] = useState(
    localStorage.getItem("userName") || sessionStorage.getItem("userName")
  );
  useEffect(() => {
    if (username) {
      getNotification();
      setUsername(localStorage.getItem("userName"));
    }
  }, [username]);

  return (
    <NotificationsContext.Provider
      value={{ getNotification, notification, loading, markNotificationAsRead }}>
      {loading && <Loader />}
      {children}
    </NotificationsContext.Provider>
  );
};

export const UseNotificationsContext = () => {
  return useContext(NotificationsContext);
};
